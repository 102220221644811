import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Homepage from './components/home';
import Mobile from './components/mobile';

function App() {

  useEffect(() => {
    const isMobile = window.innerWidth <= 768;
    if (isMobile) {
      import('./components/mobile.css');
    }
    else
    {
      import('./components/home.css');
    }
  }, []);


  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/mobile" element={<Mobile />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
